/* eslint-disable react/prop-types */
import React from 'react'
import { shape } from 'prop-types'
import { graphql } from 'gatsby'
import SEO from '@/components/Seo'
import Blocks from '@/utils/Blocks'
import { cleanProps } from '@/utils'
import useConstant from '@/hooks/useConstant'
/* eslint-disable no-unused-vars */
import { pageQuery, peopleQuery, departmentQuery } from '@/queries'
/* eslint-enable no-unused-vars */
import * as T from '@/types'

function About({
	pageContext: { media, wordpress_id },
	data: {
		people: { nodes: people },
		departments: { nodes: departments },
		page: { blocks, yoast, featured_media }
	}
}) {
	
	const data = useConstant(
		() =>
			cleanProps(blocks, media).map(item => {
				if (item.type === 'acf/our-people-block') {
					return {
						...item,
						people,
						departments
					}
				}
				return item
			}),
		[blocks, media]
	)

	return (
		<>
			<SEO {...yoast} image={featured_media} />
			<Blocks data={data} media={media} currentPage={wordpress_id} />
		</>
	)
}

About.propTypes = {
	pageContext: T.pageContext.isRequired,
	data: shape({
		page: T.page.isRequired,
		people: T.people.isRequired,
		departments: T.departments.isRequired
	})
}

export const query = graphql`
	query AboutQuery($path: String!) {
		people: allWordpressWpPerson {
			nodes {
				...peopleQuery
			}
		}
		departments: allWordpressWpDepartment {
			nodes {
				...departmentQuery
			}
		}
		page: wordpressPage(path: { eq: $path }) {
			...pageQuery
		}
	}
`

export default About
