import {
	shape,
	bool,
	string,
	number,
	object,
	arrayOf,
	oneOfType
} from 'prop-types'

export const pageContext = shape({
	media: string.isRequired
})

export const page = shape({
	title: string.isRequired,
	content: string,
	id: string.isRequired,
	wordpress_id: number.isRequired,
	blocks: arrayOf(
		shape({
			attrs: shape({
				name: string,
				wordpress_id: oneOfType([string, number]),
				data: object
			})
		})
	)
})

export const post = shape({
	title: string.isRequired,
	content: string,
	id: string.isRequired,
	featured_image_url: shape({
		wordpress_id: number.isRequired
	}),
	blocks: arrayOf(
		shape({
			attrs: shape({
				name: string,
				wordpress_id: oneOfType([string, number]),
				data: object
			})
		})
	)
})

export const caseStudies = shape({
	nodes: arrayOf(page)
})

export const posts = shape({
	nodes: arrayOf(page)
})

export const quotes = shape({
	nodes: arrayOf(
		shape({
			content: string.isRequired,
			title: string.isRequired,
			wordpress_id: number.isRequired
		})
	)
})

export const people = shape({
	nodes: arrayOf(
		shape({
			title: string.isRequired,
			department: arrayOf(number),
			featured_media: object,
			wordpress_id: number.isRequired,
			id: string.isRequired
		})
	)
})

export const departments = shape({
	nodes: arrayOf(
		shape({
			name: string.isRequired,
			wordpress_id: number.isRequired,
			id: string.isRequired
		})
	)
})

export const news = shape({
	media: string.isRequired,
	categories: arrayOf(
		shape({
			title: string.isRequired,
			url: string.isRequired
		})
	),
	entries: arrayOf(
		shape({
			title: string.isRequired,
			category: string,
			path: string.isRequired,
			id: string.isRequired,
			featured_image_url: shape({
				wordpress_id: number.isRequired
			})
		})
	),
	prev: oneOfType([string, bool]),
	next: oneOfType([string, bool])
})
